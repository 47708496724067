/**
 * /*Neon
 *
 * @format
 */

p {
	text-align: center;
	font-size: 1em;
	margin: 20px 0 20px 0;
}

a {
	text-decoration: none;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	font-size: 1.5em;
}

span:nth-child(1) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(1) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(2) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(2) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(3) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(3) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(4) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(4) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(5) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(5) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(6) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(6) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(7) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(7) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(8) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(8) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(9) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(9) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(10) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(10) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(11) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(11) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(12) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(12) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(13) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(13) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(14) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(14) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(15) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(15) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(16) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(16) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(17) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(17) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}
span:nth-child(18) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(18) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(19) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(19) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span:nth-child(20) a {
	color: #ffdd1b;
	font-family: Handlee;
}

span:nth-child(20) a:hover {
	-webkit-animation: neon3 1.5s ease-in-out infinite alternate;
	-moz-animation: neon3 1.5s ease-in-out infinite alternate;
	animation: neon3 1.5s ease-in-out infinite alternate;
}

span a:hover {
	color: #ffffff;
}
/*glow for webkit*/

@-webkit-keyframes neon1 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
			0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
			0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
	}
}

@-webkit-keyframes neon2 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
			0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
			0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
	}
}

@-webkit-keyframes neon3 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ffdd1b,
			0 0 70px #ffdd1b, 0 0 80px #ffdd1b, 0 0 100px #ffdd1b, 0 0 150px #ffdd1b;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ffdd1b,
			0 0 35px #ffdd1b, 0 0 40px #ffdd1b, 0 0 50px #ffdd1b, 0 0 75px #ffdd1b;
	}
}

@-webkit-keyframes neon4 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #b6ff00,
			0 0 70px #b6ff00, 0 0 80px #b6ff00, 0 0 100px #b6ff00, 0 0 150px #b6ff00;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #b6ff00,
			0 0 35px #b6ff00, 0 0 40px #b6ff00, 0 0 50px #b6ff00, 0 0 75px #b6ff00;
	}
}

@-webkit-keyframes neon5 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff9900,
			0 0 70px #ff9900, 0 0 80px #ff9900, 0 0 100px #ff9900, 0 0 150px #ff9900;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff9900,
			0 0 35px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 75px #ff9900;
	}
}

@-webkit-keyframes neon6 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de,
			0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de,
			0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
	}
}
/*glow for mozilla*/

@-moz-keyframes neon1 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
			0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
			0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
	}
}

@-moz-keyframes neon2 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
			0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
			0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
	}
}

@-moz-keyframes neon3 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ffdd1b,
			0 0 70px #ffdd1b, 0 0 80px #ffdd1b, 0 0 100px #ffdd1b, 0 0 150px #ffdd1b;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ffdd1b,
			0 0 35px #ffdd1b, 0 0 40px #ffdd1b, 0 0 50px #ffdd1b, 0 0 75px #ffdd1b;
	}
}

@-moz-keyframes neon4 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #b6ff00,
			0 0 70px #b6ff00, 0 0 80px #b6ff00, 0 0 100px #b6ff00, 0 0 150px #b6ff00;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #b6ff00,
			0 0 35px #b6ff00, 0 0 40px #b6ff00, 0 0 50px #b6ff00, 0 0 75px #b6ff00;
	}
}

@-moz-keyframes neon5 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff9900,
			0 0 70px #ff9900, 0 0 80px #ff9900, 0 0 100px #ff9900, 0 0 150px #ff9900;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff9900,
			0 0 35px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 75px #ff9900;
	}
}

@-moz-keyframes neon6 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de,
			0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de,
			0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
	}
}
/*glow*/

@keyframes neon1 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
			0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
			0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
	}
}

@keyframes neon2 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
			0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
			0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
	}
}

@keyframes neon3 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ffdd1b,
			0 0 70px #ffdd1b, 0 0 80px #ffdd1b, 0 0 100px #ffdd1b, 0 0 150px #ffdd1b;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ffdd1b,
			0 0 35px #ffdd1b, 0 0 40px #ffdd1b, 0 0 50px #ffdd1b, 0 0 75px #ffdd1b;
	}
}

@keyframes neon4 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #b6ff00,
			0 0 70px #b6ff00, 0 0 80px #b6ff00, 0 0 100px #b6ff00, 0 0 150px #b6ff00;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #b6ff00,
			0 0 35px #b6ff00, 0 0 40px #b6ff00, 0 0 50px #b6ff00, 0 0 75px #b6ff00;
	}
}

@keyframes neon5 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff9900,
			0 0 70px #ff9900, 0 0 80px #ff9900, 0 0 100px #ff9900, 0 0 150px #ff9900;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff9900,
			0 0 35px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 75px #ff9900;
	}
}

@keyframes neon6 {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de,
			0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
	}
	to {
		text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de,
			0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
	}
}
/*REEEEEEEEEEESPONSIVE*/

@media (max-width: 650px) {
	#container {
		width: 100%;
	}
	p {
		font-size: 3.5em;
	}
}
