/** @format */

header {
	-o-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	padding: -2px 0;
	position: fixed;
	top: 0;
	width: 100%;
	background: #111;
	border-bottom: 3px solid rgb(221, 213, 170);
}

/* GRADIEND */
.ap {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	height: 60px;
	margin: auto;
	font-family: Arial, sans-serif;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #333;
	background: rgba(4, 000, 000, 0.4);
	border-top: 2px solid rgb(229, 255, 0);
	z-index: 9999;
}

/*Image Full*/
* {
	margin: 0;
	padding: 0;
}

/*Led down*/
.lightrope {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	margin: 0 0 0 -40px;
	padding: 0;
	pointer-events: none;
	width: 100%;
}
.lightrope li {
	position: relative;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	list-style: none;
	margin: 0;
	padding: 0;
	display: block;
	width: 12px;
	height: 28px;
	border-radius: 50%;
	margin: 12px;
	display: inline-block;
	background: #fe0000;
	box-shadow: 0px 4.66667px 24px 3px #fe0000;
	-webkit-animation-name: flash-1;
	animation-name: flash-1;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}
.lightrope li:nth-child(2n + 1) {
	background: #7cff01;
	box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 13, 0.5);
	-webkit-animation-name: flash-2;
	animation-name: flash-2;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
}
.lightrope li:nth-child(4n + 2) {
	background: #fff205;
	box-shadow: 0px 4.66667px 24px 3px #fff205;
	-webkit-animation-name: flash-3;
	animation-name: flash-3;
	-webkit-animation-duration: 1.1s;
	animation-duration: 1.1s;
}
.lightrope li:nth-child(odd) {
	-webkit-animation-duration: 1.8s;
	animation-duration: 1.8s;
}
.lightrope li:nth-child(3n + 1) {
	-webkit-animation-duration: 1.4s;
	animation-duration: 1.4s;
}
.lightrope li:before {
	content: '';
	position: absolute;
	background: #222;
	width: 10px;
	height: 9.33333px;
	border-radius: 3px;
	top: -4.66667px;
	left: 1px;
}
.lightrope li:after {
	content: '';
	top: -14px;
	left: 9px;
	position: absolute;
	width: 52px;
	height: 18.66667px;
	border-bottom: solid #222 2px;
	border-radius: 50%;
}
.lightrope li:last-child:after {
	content: none;
}
.lightrope li:first-child {
	margin-left: -30px;
}

@-webkit-keyframes flash-1 {
	0%,
	100% {
		background: #fe0000;
		box-shadow: 0px 4.66667px 24px 3px #fe0000;
	}
	50% {
		background: rgba(136, 36, 6, 0.4);
		box-shadow: 0px 4.66667px 24px 3px rgba(75, 20, 3, 0.2);
	}
}

@keyframes flash-1 {
	0%,
	100% {
		background: #fe0000;
		box-shadow: 0px 4.66667px 24px 3px #fe0000;
	}
	50% {
		background: rgba(136, 36, 6, 0.4);
		box-shadow: 0px 4.66667px 24px 3px rgba(75, 20, 3, 0.2);
	}
}
@-webkit-keyframes flash-2 {
	0%,
	100% {
		background: #7cff01;
		box-shadow: 0px 4.66667px 24px 3px #7cff01;
	}
	50% {
		background: rgba(109, 189, 34, 0.4);
		box-shadow: 0px 4.66667px 24px 3px rgba(94, 255, 0, 0.2);
	}
}
@keyframes flash-2 {
	0%,
	100% {
		background: #7cff01;
		box-shadow: 0px 4.66667px 24px 3px #7cff01;
	}
	50% {
		background: rgba(109, 189, 34, 0.4);
		box-shadow: 0px 4.66667px 24px 3px rgba(94, 255, 0, 0.2);
	}
}
@-webkit-keyframes flash-3 {
	0%,
	100% {
		background: #fff205;
		box-shadow: 0px 4.66667px 24px 3px #fff205;
	}
	50% {
		background: rgba(146, 121, 7, 0.4);
		box-shadow: 0px 4.66667px 24px 3px rgba(71, 59, 3, 0.2);
	}
}
@keyframes flash-3 {
	0%,
	100% {
		background: #fff205;
		box-shadow: 0px 4.66667px 24px 3px #fff205;
	}
	50% {
		background: rgba(146, 121, 7, 0.4);
		box-shadow: 0px 4.66667px 24px 3px rgba(71, 59, 3, 0.2);
	}
}
